export default {
  'Execution Fee': '執行費',
  'This rate of return cannot be achieved': '無法達到該報酬率。',
  'Open Fee': '開倉費',
  Indicators: '指標',
  Result: '計算結果',
  'Exit Price': '平仓價格',
  Tradingview: 'Tradingview',
  'Initial Collateral': '初始保證金',
  'No Open Positions': '沒有開倉頭寸',
  'Confirm Close': '確認',
  'Are you sure you want to close all positions? lt may require multiple wallet':
    '您確定要全部平倉嗎? 它可能需要進行多筆錢包操作。',
  'By connecting to the wallet, you agree to {to}. (Last updated on 05/31/2023)':
    '連結到錢包即表示您同意 {to}。 （最後更新於 2023 年 5 月 31 日）',
  'BLEX Arbitrum Goerli Testnet': 'BLEX Arbitrum Goerli Testnet',
  'Keep Leverage {lev}x': '保持槓桿 {lev}x',
  "BLEX Vault accrues 70% of the platform's generated fees. BLEX Foundation accrues 30% of the platform's generated fees.":
    'BLP 金庫是 BLEX 上所有交易的對手方，平台產生的協議 100% 由金庫收取。',
  'Purchase BLP to provide liquidity and earn 70% of the platform fee distribution.':
    '購買 BLP 以提供流動性並賺取 100% 的平台費用。',
  'View status': '查看狀態',
  'Please select a network': '請選擇網絡',
  Network: '網絡',
  'Receive test coins': '领取测试币',
  Language: '語言',
  connected_to: '連接到 {chainName} 網',
  Error: '失敗',
  'hide error': '隐藏',
  'show error': '展示錯誤',
  'The system is currently busy': '系統繁忙，請稍後重試',
  'Transaction sent.': '請求已發送。',
  'Transaction completed!': '請求發送成功，請耐心等待執行。',
  'Close All': '一鍵平倉',
  status: '狀態',
  'In the trading module, you can open a position by selecting a pair to go long or go short, and setting the amount and quantity you want to buy. After you open a position, you can see all the information about your position in the trading record.':
    '在交易頁面中，您可以選擇要做多或做空的貨幣對，並設定要買入的金額和數量，從而建立頭寸。開倉後，您可以在交易記錄中查看有關倉位的所有信息。',
  'BLEX {testnet} Testnet': 'BLEX {testnet} 測試網',
  'Website Pickup': '網站領取',
  'New testnet contracts will be deployed this week on the Arbitrum Goerli Testnet, testnet data will be cleared. For testnet users, positions can be closed beforehand if it is desired to retain the testnet funds in the position collateral.':
    '新的測試網合約將於本週在 Arbitrum Goerli 測試網上部署，測試網數據將被清除。 對於測試網用戶，如果希望將測試網資金保留在倉位抵押品中，可以提前平倉。',
  'Edit Referral Code': '編輯推薦碼',
  'Ends at': '結束於',
  'Starts in': '開始於',
  'Decentralized Perpetual Exchange': '去中心化永續交易所',
  'Please select a language': '請選擇語言',
  USDT: 'USDT',
  'Funding Fee / Day:': '資金費 / 天：',
  'Using Friendly': '使用良好',
  'Trading Smooth': '交易順暢',
  copy: '推薦鏈接已復製到剪貼板。',
  'Stake BLE and earn Escrowed BLEX rewards and x% of the platform fee distribution.':
    '質押 BLE 可获得 esBLEX 獎勵和 x% 的平台費用。',
  'BLE Vault APR': 'BLE 金庫年收益率',
  'Coming Soon': '即將上線',
  Telegram: 'TG',
  'in the last 24h': '過去 24 小時',
  'Purchase BLP to provide liquidity and earn 100% of the platform fee distribution.':
    '購買 BLP 以提供流動性並賺取 100% 的平台費用。',
  Referral: '返傭',
  'Dear users, you can execute your trading strategy on BLEX without KYC while controlling of your own assets. Start a silky journey of decentralized perpetual contract trading immediately!':
    '親愛的用戶,無需 KYC,自主掌握資產,您即可在 BLEX上執行您的交易策略。立即開啟順暢的去中心化永續合約交易之旅吧！',
  'By binding an invitee, or becoming an invitee, you can get the corresponding rebate bonuses and rebate discounts. The bonus or discount will be automatically paid to your wallet every week.':
    '綁定受邀者或成為受邀者，即可獲得相應的返傭獎金和交易折扣。獎金或折扣將每週自動存入您的錢包。',
  Staking: '質押',
  'You can purchase $BLP revenue-added products from the platform, and you can withdraw the proceeds to your wallet at any time after purchase.':
    '您可以在平台上購買 $BLP 收益增值產品，購買後可隨時將收益提現到您的錢包。',
  Trading: '交易',
  Connections: '連結錢包',
  'Select a wallet supported by BLEX by clicking the "Link Wallet" button in the navigation bar. After linking wallets select a network supported by BLEX and enjoy BLEX!':
    '點擊導航欄中的「連結錢包」按鈕，選擇 BLEX 支持的錢包。連結錢包後，選擇 BLEX 支持的網路即可享受 BLEX 的交易！',
  'No Open Orders': '無未完成訂單',
  'BLEX T&C': 'BLEX 條款及細則',
  'No History': '暫無記錄',
  Hide: '隱藏',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDT every Wednesday.':
    '綁定推薦代碼可獲得高達 10% 的交易折扣，交易折扣將於每週三以 USDT 返還。',
  'Receive Discounts': '獲取折扣',
  'Trading Discount': '交易折扣',
  'Bind Referral Code': '綁定推薦碼',
  'Input a referral code to benefit from trade discount.': '輸入推薦碼以享受交易折扣。',
  'Get discounts on transaction fees when you make a trade.': '進行交易時獲得交易手續費折扣。',
  'The discounts will be paid out in USDT every Wednesday.': '交易折扣將於每週三以 USDT 返還。',
  'Sorry, the page you visited does not exist': '抱歉，您訪問的頁面不存在',
  Successful: '成功',
  'Bridge ETH to Arbitrum': '將 ETH 跨鏈橋接至 Arbitrum',
  'There is not enough ETH in your account on Arbitrum to send this transaction.':
    '您在 Arbitrum 上的帳戶中沒有足夠的 ETH 來發送此交易。',
  'Transaction failed due to RPC error.': '由於 RPC 錯誤，交易失敗。',
  'Please try changing the RPC url in your wallet settings.': '請嘗試在您的錢包設置中更改RPC URL。',
  'Transaction failed': '交易失敗',
  success: '成功',
  'Share your referral code to receive up to 15% trading rebate. Rebates will be paid out in USDT every Wednesday.':
    '分享您的推薦代碼即可獲得高達 15% 的交易返傭。 返傭將於每週三以 USDT 返還。',
  'Referral Code:': '推薦碼：',
  'Index Perpetual Exchange': '指數永續交易所',
  Request: '請求 {symbol}',
  'Drops are limited to1 request in 8 hours.': '8 小時內僅限 1 次領取。',
  FollowTwitter: '關注 Twitter 和 Telegram 以獲取更多{marketName}的資訊。',
  'Wallet Address:': '錢包地址：',
  'Total Balance (USDT)': '總餘額（USDT）',
  'Short Open Interest: {value}': '空頭未平倉頭寸：{value}',
  'Long Open Interest: {value}': '多頭未平倉頭寸：{value}',
  'Open Interest Balance': '未平倉頭寸餘額',
  'BLEX is a decentralized exchange allowing trading without the need for a username or password. The platform uses an aggregate price feed which reduces the risk of liquidations from temporary wicks.':
    'BLEX 是一個去中心化的交易所，無需用戶名或密碼即可進行交易。其基礎是一個安全、公平和有效的指數價格模型。',
  'How do BLEX Trade work?': '怎麼在 BLEX 交易？',
  LONG: '多',
  SHORT: '空',
  'Price Above Liq. Price': '價格高於清算價',
  'Price Below Liq. Price': '價格低於清算價',
  Slider: '滑塊',
  Customize: '自定義',
  'Your wallet is not connected to': '您的錢包未連接到 {chainName}',
  'Sell BLP': '出售 BLP',
  'Buy BLP': '購買 BLP',
  'Selling...': '出售中...',
  'Buying...': '購買中...',
  'Update Notice': '更新通知',
  'Considers settled (closed) positions': '考慮已結算（平倉）頭寸',
  'Fees are not factored into PnL': '費用不計入損益',
  Volume: '交易量',
  'Last Month': '上個月',
  'Last 2 Months': '過去 2 個月',
  'Last 3 Months': '過去 3 個月',
  Users: '用戶',
  'Users Actions': '用戶操作',
  'Traders Profit vs. Loss': '交易者盈利和虧損',
  'Traders Net PnL': '交易者淨盈利和虧損',
  'Total Users': '所有用戶',
  'BLP Pool': 'BLP 池',
  'Total Fees': '總費用',
  Analytics: '分析',
  '+$0': '+$0',
  "The BLP Vault is the counterparty to all trades on BLEX and the vault captures 100% of the platform's generated fees.":
    'BLP 金庫是 BLEX 上所有交易的對手方，平台產生的協議 100% 由金庫收取。',
  Status: '狀態',
  'What is BLEX':
    'BLEX旨在建立一個去中心化的永續交易所協議。其團隊成員來自頂級CEX，知名對沖基金和區塊鏈公司。BLEX的治理將由BLEX DAO確定。BLEX在新加坡和香港設有辦事處，DAO成員通過遠程工作從世界各地進行貢獻。我們期待您成為BLEX的成員。',
  earnTip: '提示：交易者和 LP 都有損失風險。在參與 BLEX 協議之前，請確保熟悉細節。',
  'Media Kit': '媒體工具',
  BugBounty: '賞金計劃',
  'I have understood': '我已明白',
  'We recognize that you are trying to access BLEX from a China IP address, please note that per our':
    '我們發現您正在嘗試從中國 IP 地址訪問 BLEX，請注意，根據我們的',
  ', we are unable to serve any users from an {regionName} IP.': '，我們無法為任何來自中國 IP 的用戶提供服務。',
  'footer.ifYouHaveAnyQuestions': '如果您對我們產品的功能有任何疑問，我們很樂意與您交談，請聯繫我們。',
  'footer.products': '產品',
  'Referral Terms': '推薦條款',
  'footer.reservedRights': '© 2023 BLEX 版權所有。',
  'Welcome to BLEX': '歡迎來到BLEX',
  'wallet.connect': '連結以登錄或創建帳戶',
  'wallet.lastUpdated': '連結到錢包即表示您同意 BLEX 條款和協議。 （最後更新於 2023 年 5 月 31 日）',
  'Perpetual Exchange': '永續交易所',
  'Perpetual Trading': '永續交易',
  'By staking, LPs can earn up to 70% of the trading fees and capture the value of the protocol.':
    '通過質押，流動性提供者可以獲得高達 70% 的交易費用並獲取協議的價值。',
  'Earn Now': '立即賺取',
  'View more': '查看更多',
  'Explore The Endless': '探索無盡',
  'Possibilities With BLEX': 'BLEX 的可能性',
  'BLEX is an on-chain decentralized exchange for perpetual futures, ensuring secure funds and transparent data.':
    'BLEX是永續合約鏈上去中心化交易所，保證資金安全、數據透明。',
  'Smooth Trading': '交易順暢',
  'Traders can open positions with zero spread using the index price, while the LP vault provides exceptional depth.':
    '交易者可以使用指數價格以零點差開倉，而有限合夥人金庫則提供卓越的深度。',
  'Get Started': '開始使用',
  'Trading is available on both the web and mobile app, with easy wallet integration. Joining in only requires one simple step.':
    '交易可在網絡和移動應用程式上進行，並可輕鬆集成錢包。 加入只需一個簡單的步驟。',
  'Always at your side': '永遠在你身邊',
  'Join the BLEX community now!': '立即加入 BLEX 社區！',
  Mint: '鑄造',
  'We are continuously launching new perpetual contracts markets.': '我們不斷推出新的永續合約市場。',
  'Stake BLE and BLP to earn rewards.': '質押 BLE 和 BLP 以獲得獎勵。',
  'Get test tokens': '獲取測試令牌。',
  'Wallet Address': '錢包地址',
  'Share Revenue': '分享收益',
  'Txn failed.': '發送失敗。',
  ', we are unable to serve any users from an ': '，我們無法為任何用戶提供服務',
  Switch: '選擇',
  'Connect Wallet': '連結錢包',
  'Drops are limited to 1 request in 8 hours.': '8 小時內僅限 1 次領取。',
  'Connect your wallet': '連結你的錢包',
  Home: '首頁',
  'Bug bounty': '賞金計劃',
  Test: '測試',
  'Considers Settled (Closed) Positions': '考慮已結算（平倉）頭寸',
  'Fees Are Not Factored Into PnL': '費用不計入損益',
  'The total number of positions opened and closed during the event': '活動之間累計的開倉和平倉總次數',
  Days: '天',
  Hours: '小時',
  Minutes: '分鐘',
  Seconds: '秒',
  PNL: 'PNL',
  'The BLEX bug bounty program is now live! Up to $100,000 in rewards for a key bug report.':
    'BLEX 賞金計畫現已上線！ 提交關鍵漏洞可獲得高達 100,000 美元的獎勵。',
  'Assets Under Management': '管理資產',
  Service: '服務',
  'Stake BLE and earn Escrowed BLEX rewards and 30% of the platform fee distribution.':
    '質押 BLE 以賺取託管 BLE 獎勵和 30% 的平台費用收入。',
  'Staking to Earn': '質押賺取',
  'Bind a referral code to get up to 10% trading discount. The trading discount will be returned in USDC every Wednesday.':
    '綁定推薦代碼可獲得高達 10% 的交易折扣，交易折扣將於每週三以 USDT 返還。',
  'Create referral code and start earning rebates!': '創建推薦碼並開始賺取返利！',
  'Invite User': '邀請用戶',
  'Share your referral code on social media platforms such as Twitter and Facebook.':
    '在 Twitter 和 Facebook 等社交媒體平台上分享您的推薦碼。',
  'Earn Rebates': '賺取返傭',
  'When you successfully invite users to trade, the users can enjoy reduced trading fees and you can receive trading rebates.':
    '當您成功邀請用戶交易時，用戶可以享受交易手續費的優惠，您還可以獲得交易返利。',
  Referrers: '推薦人',
  'Your Active Referral Code': '您的有效推薦碼',
  'Please input a referral code to benefit from fee discounts.': '請輸入推薦碼以享受費用折扣。',
  'Referral code updated failed.': '推薦碼更新失敗。',
  'Adding referral code failed.': '添加推薦碼失敗。',
  'Submitting...': '正在提交...',
  'Checking code...': '檢查推薦碼...',
  Action: '動作',
  'Creating...': '創建中...',
  'Create code failed.': '創建失敗',
  'Create Referral Code': '創建推薦碼',
  'Rebates earned by this account as a trader.': '該帳戶作為交易者賺取的折扣。',
  'Volume traded by this account with an active referral code.': '使用此帳戶推薦碼的有效交易量。',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday.':
    '這是您綁定推薦人的等級，以及您將獲得的開平倉費用的折扣比例，折扣在每週三空投到帳戶。',
  'Total Claimable Reward': '總可領取獎勵',
  'Claim all': '全部提取',
  'Stake BLE and earn Escrowed BLEX rewards and 70% of platform fees distributed.':
    '質押 BLE 以賺取託管 BLE 獎勵和 30% 的平台費用收入。',
  'Your Wallet': '你的錢包',
  'Your Staked': '你的質押',
  'Total Staked': '總質押',
  'Buy BLE': '購買 BLE',
  Stake: '質押',
  Unstake: '取消質押',
  'Provide liquidity by purchasing BLP with USDT. and earn 70% of platform fees distributed.':
    '通過用 USDT 購買 BLP 來提供流動性，並賺取 70% 的平台費用收入。',
  'BLE Points': 'BLE 積分',
  'The fuel for boosting esBLE to BLE.': '將 esBLE 轉化為 BLE 的燃料。',
  'Buy BPT': '購買 BPT',
  'Escrowed BLE': '託管 BLE',
  'Convert esBLE to BLE through Boost and Vest.': '通過 Boost 和 Vest 將 esBLE 轉換為 BLE。',
  Boost: 'Boost',
  Vest: 'Vest',
  'Enjoy BLP Rewards': '獲取 BLP 獎勵',
  'Total BLP amount': '總 BLP 數量',
  'Quantity of NFTs.': 'NFT 數量',
  Expand: '展開',
  'Mint successful': '鑄造成功',
  'Hurry up stake and enjoy the Rewards.': '趕快質押獲取獎勵吧。',
  Done: '完成',
  'Owning this NFT entitles you to Enjoy BLP Rewards, Double BPT Income!':
    '擁有此 NFT 即可享受對應 BLP 獎勵，雙倍 BPT 收入！',
  'Your Stake': '你的質押',
  'Stop loss': '止損',
  'Take profit': '止盈',
  'Creating Order...': '創建訂單中...',
  'Longing...': '做多中...',
  'Shorting...': '做空中...',
  'Liq. Price': '清算價',
  'View Chart': '查看表格',
  Export: '導出',
  price: '價格',
  'Net Value': '淨資產',
  'Net Value: Initial Collateral + PnL - Funding Fee - Close Fee': '淨資產：初始保證金 + 損益 - 資金費 - 平倉費',
  Liq: '清算',
  'Price below liq. price': '價格低於清算價',
  'Price above liq. price': '價格高於清算價',
  'Slippage should be less than': '滑點應該小於',
  'Slippage should be more than': '滑點應該大於',
  'Creating order...': '創建訂單中...',
  'Closing...': '平倉中...',
  'Index Price:': '指數價格：',
  'Close:': '平倉：',
  'Available:': '可用：',
  'Withdrawing...': '提取中...',
  'Depositing...': '充值中...',
  MAX: '最大值',
  Amount: '數量',
  'Size:': '頭寸：',
  'Welcome to BLEX {chainName}. Please pay attention to get the testToken first.':
    '歡迎來到BLEX {chainName}。請先領取測試Token。',
  All: '全部',
  'Open Long': '開多',
  'Open Short': '開空',
  'Close Long': '平多',
  'Close Short': '平空',
  Liquidation: '清算',
  'Partial Liquidation': '部分清算',
  'Partial liquidation': '部分清算',
  'Withdraw Collateral': '提取保證金',
  'Deposit Collateral': '存入保證金',
  '5m': '5分鐘',
  '15m': '15分鐘',
  '1h': '1小時',
  '4h': '4小時',
  '1d': '1天',
  Executed: '已執行',
  Canceled: '已取消',
  Original: '基礎版',
  TradingView: 'TradingView',
  Currency: '幣種',
  'Balance:': '餘額:',
  'The BLP Vault is the counter party to all trades on BLEX.': 'BLEX 上的所有交易的交易對手方是 BLP Vault。',
  'How do BLEX rewards work?': 'BLEX 獎勵如何施行？',
  tradesNoHistory: '暫無歷史記錄',
  'How do BLEX Earn work?': 'BLEX 獎勵如何施行？',
  'About Blex': '關於我們',
  'Connect your wallet to continue': '連結錢包',
  Filter: '篩選',
  'No Record': '無記錄',
  'Go trade now!': '即刻交易!',
  Cancle: '取消',
  'Competitions 🔥': '交易大賽',
  Continue: '繼續',
  Competitions: '競賽',
  Decentralized: '去中心化',
  'The index price retrieval is incorrect, please try again later.': '指數價格查詢錯誤，請稍後再試。',
  'User friendly': '用戶友好',
  'Event Details>>': '活動詳情>>',
  'BLEX Arbitrum Mainnet Launch on Aug 6th!  Reserve now to share the total $10000 Prize Pool.':
    'BLEX Arbitrum 主網將於 8 月 6 日上線！立即預約即可分享總計 10000 美元的獎池。',
  'Congrats! Reservation in Success! Continue to split the final $10000 USDT rewards!':
    '恭喜！預約成功！繼續瓜分最終的 10000 美元 USDT 獎勵！',
  'Reservation Success': '預約成功',
  Reserve: '預約',
  'Countdown to the launch': '上線倒計時',
  Connect: '連結',
  'Enter Referral code': '輸入推薦碼',
  'Open Interest': '未平倉頭寸',
  AnalyticsDesc: '更新於 {time} , {block}區塊',
  '24h Change': '24小時變化',
  '24h High': '24h最高價',
  '24h Low': '24h最低價',
  'Buy / Sell BLP': '買/賣 BLP',
  IMakeTrades: '我已經在BLEX完成了 {trades} 筆交易，快來加入我吧！',
  'Index Price': '指數價格',
  'Share Position': '分享倉位',
  'PnL After Fees:': '扣除費後的損益：',
  'Open Fee {value}': '開倉費：{value}',
  'Close Fee {value}': '關倉費：{value}',
  'Funding Fee {value}': '資金費：{value}',
  'PnL {value}': '損益：{value}',
  'Initial Collateral {value}': '初始保證金：{value}',
  'Use the edit collateral icon to deposit or withdraw collateral.': '使用編輯保證金圖標存入或提取保證金。',
  'Funding Fee:': '資金費：',
  'Collateral:  Initial Collateral - Funding Fee': '保證金：初始保證金 - 資金費',
  'Initial Collateral:': '初始保證金：',
  ConfirmLong: '做多 {symbol}',
  ConfirmShort: '做空 {symbol}',
  Confirm: '確認',
  Networks: '網絡',
  ExecutionFee: '執行費：{fee}',
  'The execution fee is only charged when the order is executed or canceled due to invalid execution.':
    '只有在訂單被執行或因執行無效而被取消時，才收取執行費。',
  OpenFeeLabel: '開倉費：{fee}',
  CloseFeeLabel: '關倉費：{fee}',
  FundingFee: '資金費：{fee}',
  Fees: '費用',
  Size: '大小',
  Available: '可用',
  MinResidualCollateralUSD: '最小剩餘抵押品：{USD}美元',
  LeftoverNetValue: '剩餘淨值低於 ${cleanFee}',
  LeftoverCollateral: '抵押品低於 ${cleanFee}',
  AcceptMinimum: '接受最小值並{action}',
  ExistingIncrease: '您現有的 {isLong} {toSymbol} 限價單有多個',
  ExistingPosition: '您已經擁有一個 {isLong} 倉位，將增加現有倉位的大小。',
  ConfirmOrderShort: '確認 {toSymbol} 空單',
  ConfirmOrderLong: '確認 {toSymbol} 多單',
  'Connected with': '鏈接到',
  'View on Explorer': '在區塊鏈瀏覽器中查看',
  'Txn failed': '交易失敗',
  View: '查看',
  text: '文本',
  'No assets yet？You can bridge ETH from another network, transfer ETH from an exchange':
    '還沒有資產嗎？你可以從跨鏈橋或交易所劃轉ETH。',
  Homepage: '主頁',
  'Trading Volume': '交易量',
  Open: '開倉',
  Rank: '排名',
  Account: '帳戶',
  '': '',
  'Cum.Size': '累計數量',
  'Cum.Collateral': '累計抵押',
  Prize: '獎品',
  Reward: '獎勵',
  'Total Profit': '總利潤',
  'The total volume accumulated between the activity': '活動期間累計交易量。',
  'BLP Supply': 'BLP供應量',
  'The total BLP supply accumulated between the activity': '活動期間累計BLP供應量',
  'Prize Pool': '獎金池',
  'The estimated amount distirbuted to all top traders by competition end results':
    '競賽結束後分配給所有頂級交易者的估計金額。',
  Trades: '交易次數',
  'Rank List': '排行榜',
  'Profit Rank': '利潤排名',
  Profit: '利潤',
  'Trade ROI Rank': '交易ROI排名',
  'Trade ROI': '交易ROI',
  'Trading Data': '交易數據',
  'Total volume': '總交易量',
  'Fees Paid': '已支付手續費',
  'Trading Fees': '交易手續費',
  'Open/Close Fee': '開倉/平倉手續費',
  'Funding Fee': '資金費用',
  'Read More': '查看更多',
  'Average profit': '平均利潤',
  'Average loss': '平均虧損',
  'Address copied to your clipboard': '地址已復製到剪貼板',
  Traders: '交易者',
  'Enter referral code': '輸入推薦碼',
  'Please input a referral code to benefit from fee discounts': '請輸入一個推薦碼以獲得費用折扣',
  'Save Changes': '保存更改',
  'Referral code updated!': '推薦碼已更新！',
  'Referral code updated failed': '更新推薦碼失敗',
  'Adding referral code failed': '添加推薦碼失敗',
  'Same as current active code': '與當前推薦碼相同',
  Submitting: '正在提交',
  'Checking code': '正在檢查代碼',
  'Referral code does not exist': '推薦碼不存在',
  'Referral link copied to your clipboard': '推薦鏈接已復製到剪貼板。',
  Creating: '創建中',
  'Enter a code': '輸入推薦碼',
  'Code already taken': '推薦碼已被使用',
  Create: '創建',
  title: '標題',
  desc: '描述',
  'Total Trading Volume': '總交易量',
  'Volume traded by this account with an active referral code': '此帳戶通過有效推薦碼交易的總量',
  'Total Traders Referred': '總推薦交易者數',
  'Rebates earned by this account as a trader': '此帳戶作為交易者賺取的返利',
  'Total Rebates': '總返利',
  'Tier 1 (5% rebate)': '第一梯隊（5% 折扣）',
  'Edit Refferal code': '編輯推薦碼',
  'Active Referral Code': '有效推薦碼',
  'Tier 1 (5% discount)': '一級（5% 折扣）',
  'You will receive a 5% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday':
    '您將獲得開倉和平倉費用的 5% 折扣，此折扣將於每個星期三空投至您的帳戶',
  'Tier 1': '一級',
  Rewards: '獎勵',
  'Stake USDT and earn rewards from fees generated on the BLEX.': '質押 USDT 並從 BLEX 產生的費用中獲得獎勵。',
  'Learn More': '了解更多',
  'Enter an amount': '請輸入數量',
  'Insufficient USDT balance': 'USDT 餘額不足',
  'Insufficient BLP balance': 'BLP 餘額不足',
  Buy: '購買',
  Sell: '出售',
  'BLP Vault APR': 'BLP Vault 年化收益率',
  'Your stake': '您的贊助份額',
  rewarded: '獎勵',
  'Pending rewards': '待領取的獎勵',
  'Harvest USDT': '獲取 USDT',
  'Get Insurance': '獲取保險',
  Advanced: '高級選項',
  'Stop Loss': '止損',
  'Take Profit': '止盈',
  Slippage: '滑點',
  'Note that a low allowed slippage, e.g. less than 0.5%, may result in failed orders if prices are volatile.':
    '請注意，允許的滑點較低（如低於 0.5%）可能會導致在價格波動時訂單失敗。',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity.Values change with the last price of the symbo':
    '在 BLEX 中使用像 USD 這樣的穩定幣進行交易，顯示的持有量是基於實際幣數量計算的。價值會隨符號的最後價格而變動。',
  label: '標籤',
  'Entry Price': '開倉價各線',
  'Liq.Price': '強平價格',
  Type: '類型',
  Spread: '點差',
  Time: '時間',
  Order: '訂單',
  Position: '持有',
  Mark: '標記',
  'Enter a price': '輸入價格',
  'Price above current price': '高於當前價格',
  'Price below current price': '低於當前價格',
  'Stop loss above current price': '止損高於當前價格',
  'Stop loss below current price': '止損低於當前價格',
  'Stop loss below liq.Price': '止損低於強平價格',
  'Stop loss above liq.Price': '止損高於強平價格',
  'Take profit below current price': '止盈低於當前價格',
  'Take profit above current price': '止盈高於當前價格',
  'Take profit below liq.Price': '止盈低於強平價格',
  'Take profit above liq.Price': '止盈高於強平價格',
  'Slippage should be less than %': '滑點應小於 %',
  'Slippage should be more than %': '滑點應大於 %',
  Approving: '正在批准',
  'Incorrect network': '網路錯誤',
  'Create limit order': '創建限價訂單',
  'Available Liquidity': '可用流動性',
  titleId: '標題 ID',
  Overview: '首頁',
  More: '更多',
  'Your Trades': '您的交易 {trades}',
  'Your Rank': '您的排名 {tradeROIRank}',
  'Your price': '獎勵: {tradeROIRankShow}',
  'Your supply': '您的供應 {yourStake}',
  'Your Volume': '您的成交量 {total}',
  AdvancedStopLess: '當指數價格達到 {stopLoss} USD 時，它將觸發止損，並且預估的 PnL 將為 {stopLossPNL} USD。',
  AdvancedProfit: '當指數價格達到 {takeProfit} USD 時，它將觸發止盈，並且預估的 PnL 將為 {takeProfit} USD。',
  'Your Rewards': '您的獎勵',
  'BLP Vault': 'BLP Vault',
  'Total Supply': '總供應量',
  'BLP Vault balance': 'BLP Vault 餘額',
  Entry: '進場',
  MinPayUSD: '最低支付：{min} 美元',
  MaxPayUSD: '最大訂單量：{max} 美元',
  MAXLeverage: '最大槓桿：{max}.00x',
  MinLeverage: '最小槓桿：{min}.00x',
  MAXExceeded: '超過最大{toSymbol} {pos}',
  ConnectWallet: '連結錢包',
  Index: '指數',
  Price: '價格',
  Disconnect: '斷開連結',
  Perpetual: '永續合約',
  Exchange: '交易所',
  Copy: '複製',
  Download: '下載',
  Twitter: '推特',
  Share: '分享',
  Winrate: '勝率',
  Average: '平均',
  profit: '盈利',
  loss: '虧損',
  Postion: '倉位',
  Net: '淨',
  Value: '價值',
  Close: '關閉',
  Collateral: '抵押品',
  Edit: '編輯',
  Limit: '限價',
  Cancel: '取消',
  Fund: '資金',
  Fee: '手續費',
  Submit: '提交',
  fundFeeInfoP1: '資金費率主要用於平衡多頭和空頭的比例。',
  TermsAndConditions: '服務條款',
  MediaKit: '媒體資源包',
  Approve: '批准',
  'Connect wallet': '連結錢包',
  'Hide Other Pairs': '隱藏其他交易對',
  Referrals: '推薦',
  ReferralsSubTitle: '通過 BLEX 推薦計劃獲得費用折扣和獎勵返利。',
  CompetitionsSubtitle: '競賽將表彰獲得最高總利潤和交易投資回報率的交易者。',
  Governance: '治理',
  Community: '社群',
  Developers: '開發人員',
  Docs: '文件',
  'Contact us': '聯絡我們',
  'Who We Are?': '我們是誰？',
  'Terms of Service': '服務條款',
  'Trade BTC, ETH and other top cryptocurrencies with up to 100x leverage directly from your wallet.':
    '透過您的錢包以最高100倍槓桿交易BTC、ETH和其他熱門加密貨幣。',
  'For trader': '對於交易者',
  'Using their own wallet to do perpetual trading, with 100x (up to) leverage, 0 spread and excellent depth.':
    '使用您自己的錢包，在100倍槓桿、0滑點和極佳的交易深度的交易所進行衍生品交易！',
  Trade: '交易',
  'For vault LP': '用於儲備金 LP',
  'Earn trading fee with in stable coin staking. No impermanent loss.': '在穩定幣質押中賺取交易費，無不穩定損失。',
  Earn: '賺取',
  'Asset Safety': '資產安全',
  'BLEX is a on-chain DEX of perpetual futures. Fund security and data transparency.':
    'BLEX是一個永續合約的鏈上DEX。資金安全和數據透明。',
  'Avoid IL': '避免不穩定損失',
  'Following the perpetual futures mechanism, the single currency USD LP vault avoids Impermanent Losses.':
    '遵循永續合約機制，單幣種USD LP儲備金避免了不穩定損失。',
  'Value Capture': '價值捕獲',
  'Earn up to 70% trading fee via staking. Capture the value of the protocol.':
    '通過質押賺取高達70%的交易費。捕獲協議的價值。',
  'Trading is available both on WEB and mobile APP. Connecting to the wallet you are using now. Only one step to join in.':
    '在WEB和移動APP上都可以進行交易。連結您正在使用的錢包。只需一步即可加入。',
  learnMore: '了解更多',
  'referrals.question': 'BLEX推薦計劃如何實施？',
  'referrals.answer1': '折扣和返利將每週以USDT的形式分發。',
  'referrals.answer2': '推薦計劃為交易者提供高達10%的折扣，為推薦人提供15%的返利。',
  'referrals.table.date': '日期',
  'referrals.table.amount': '數量',
  'referrals.table.transaction': '交易',
  'referrals.table.title': '獎勵分配歷史記錄',
  'referrals.table.no_history': '還沒有獎勵分配歷史記錄。',
  'referrals.looksLike': '看起來您沒有推薦碼。在下面輸入代碼並點擊提交以在鏈上創建它。',
  'referrals.referralCode': '推薦碼',
  'referrals.tradedVolume': '總交易量',
  'referrals.tradersReferred': '已推薦交易者數',
  'referrals.totalRebates': '返利總額',
  referrals: '推薦碼',
  'referrals.noCodes': '還沒有推薦碼。',
  'referrals.ttwitterText': '直接使用錢包進行ETH/USD和其他頂級加密貨幣的多達100倍槓桿交易。@blex.io',
  'referrals.codeLenTip': '推薦碼不能超過20個字符',
  'referrals.title': '輸入推薦碼',
  'referrals.subTitle': '請輸入推薦碼以獲得費用減免',
  'referrals.btnText': '提交',
  'referrals.editBtnText': '保存更改',
  'referrals.successMsg': '推薦碼已更新！',
  'CancelReason.Padding': '未定義',
  'CancelReason.Liquidation': '強制平倉並自動取消',
  'CancelReason.PositionClosed': '倉位已關閉並自動取消',
  'CancelReason.TpAndSlExecuted': '止盈止損已執行並自動取消',
  'CancelReason.SysCancel': '合約執行時出現錯誤',
  'CancelReason.PartialLiquidation': '部分強制平倉並自動取消',
  above: '高於',
  below: '低於',
  long: '做多',
  short: '做空',
  tp: '止盈',
  sl: '止損',
  and: '和',
  OrderType: '訂單類型',
  'Orders.noPos': '無未平倉頭寸，必須先開倉才能下單。',
  'Orders.orderSize': '訂單大小大於頭寸大小，只有在存在頭寸的情況下才能下單。',
  'Orders.limitReached': '觸發訂單已達到上限，止損/止盈訂單將無法創建。',
  'Orders.stopLoss': '止損價格 {triggerAbove} 爆倉價格，止損訂單將無法創建。',
  'Orders.orderWithType': '此訂單為{type}訂單。',
  'Orders.orderSet': '此訂單已設定{TP} {and} {SL}訂單。',
  'Orders.pnlText': '當指數價格達到{triggerPrice}美元時，將觸發{type}訂單，預計盈虧將為{PNL}美元。',
  'Orders.tpOrSL': '止盈/止損',
  'Orders.editOrderTitle': '編輯{type}訂單',
  Positions: '倉位',
  History: '歷史',
  _Orders: '訂單',
  orderPrice: '訂單價格',
  indexPrice: '指數價格',
  tpSL: '止盈止損',
  cancelAll: '取消全部',
  WARNING: '警告',
  liqPrice: '強制平倉價格',
  enterPrice: '輸入一個價格',
  priceAboveCurrent: '價格高於當前價格',
  stopLossAboveOrder: '止損高於訂單價格',
  stopLossBelowLiq: '止損低於爆倉價格',
  takeProfitBelowOrder: '止盈低於訂單價格',
  priceBelowCurrent: '價格低於當前價格',
  stopLossBelowOrder: '止損低於訂單價格',
  stopLossAboveLiq: '止損高於強平價格',
  takeProfitAboveOrder: '止盈高於訂單價格',
  priceBelowLiq: '價格低於爆倉價格',
  priceAboveLiq: '價格高於爆倉價格',
  updatingOrder: '正在更新訂單...',
  updateOrder: '更新訂單',
  Pair: '交易對',
  'position.noOpenPositions': '沒有開倉頭寸',
  'position.sharePosition': '分享倉位',
  'position.warinitePositions': '此頭寸扣除借款費用後擁有低保證金，存入更多保證金以降低頭寸的清算風險。',
  Opening: '開倉中...',
  Closing: '平倉中...',
  editCollateral: '修改抵押品',
  entryPrice: '開倉價格',
  netValue: '淨值',
  Market: '市場',
  Trigger: '觸發價',
  welcomeTo: '歡迎來到{type}。',
  trustedByUser: '已獲得{uniqueUsersTotal}名交易者的信任。',
  addedLastWeek: '上週新增：{money}',
  tierAndRebate: '等級 {level}（{amount}% 返利）',
  tierAndDiscount: '等級 {level}（{amount}% 折扣）',
  closeIsLongSymbol: '平 {isLong} {symbol}',
  'Stake USDT and earn rewards from fees generated on the BLEX': '質押 USDT 並從 BLEX 產生的費用中獲得獎勵',
  'The BLP Vault is the counter party to all trades on BLEX': 'BLEX 上的所有交易的交易對手方是 BLP Vault',
  "BLEX Vault accrues 70% of the platform's generated fees.BLEX Foundation accrues 30% of the platform's generated fees":
    'BLEX Vault 將獲得了平台產生的 70% 的費用，而 BLEX 基金會獲得了平台剩下產生的 30% 的費用。',
  'Hello!': '你好',
  '24h Trades': '24 小時交易',
  '24h Volume': '24 小時交易量',
  'No data': '暫無數據',
  'Up to 20%': '高達 20%',
  'Stake USDT and earn rewadrs from fees generated on the BLEX': '質押 USDT 並從 BLEX 產生的費用中獲得獎勵。',
  'Lowest transaction fees': '最低交易費用',
  'Referral code added!': '推薦碼已添加',
  'Create code failed': '推薦碼創建失敗',
  'Generate Referral Code': '生成推薦碼',
  'No rewards distribution history yet': '暫無獎勵分配歷史記錄',
  'Trading units using stable coins in BLEX such as USD, open position amounts shown are calculated based on the actual coin quantity. Values change with the last price of the symbol.':
    '在 BLEX 中使用穩定幣（如 USD）交易時，顯示的持有金額是基於實際幣種數量計算的。該數值會隨著交易對最新價格的變化而變化。',
  'You have an active trigger order that could impact this Position': '您有一個正在生效的觸發訂單，可能會影響此持倉',
  'You have multiple active trigger orders that could impact this Position':
    '您有多個正在生效的觸發訂單，可能會影響此持倉',
  'You have an active Limit Order that could impact this Position': '您有一個正在生效的限價訂單，可能會影響此持倉',
  'Creating Order': '正在創建訂單',
  Longing: '開多中',
  Shorting: '開空中',
  'Position Size': '倉位大小',
  Leverage: '槓桿',
  Side: '方向',
  Increase: '增加',
  Long: '做多',
  Short: '做空',
  'Price below liq.price': '價格低於清算價格',
  'Price above liq.price': '價格高於清算價格',
  'Max close amount exceeded': '最大平倉數量超過限制',
  'Creating order': '正在創建訂單',
  'Create order': '創建訂單',
  'Keep Leverage': '保持槓桿',
  Receive: '收到',
  Deposit: '存款',
  Withdraw: '取款',
  'Invalid liq.price': '無效的清算價格',
  Withdrawing: '正在提款',
  Depositing: '正在存款',
  Pay: '支付',
  'Bug Bounty': '賞金計劃',
  'Submit a Bug': '提交漏洞',
  Balance: '餘額',
  'Collateral: Initial Collateral - Funding Fee': '保證金：初始保證金 - 資金費',
  'I have made trades on BLEX, come with me!': '我已經在BLEX完成了 {trades} 筆交易，快來加入我吧！',
  '+$0.00': '+$0.00',
  ReferralTerms: '推薦條款',
  'Confirm Limit Order': '確認限價訂單',
  'Create Order': '創建訂單',
  Prev: '上一頁',
  'Share Time': '分享時間',
  Next: '下一頁',
  'Next Funding Fee': '下一次資金費',
  Countdown: '倒計時',
  'Waiting for update': '等待更新',
  'The payment rate exchanged between the long and short positions for the next funding. If the funding rate is positive, pay funding fee. If negative, receive funding fee.':
    '多頭及空頭倉位在下個資金時段要交換的資金費率。若資金費率為正，支付資金費。若資金費率為負，收取資金費。',
  'Your network is not good, please reconnect your network': '您的網絡不正常，請重新連接網絡',
  'Total Volume': '總交易量',
  'Top{no}': '第{no}名',
  'Share {no}': '瓜分{no}獎金池',
  'Futures Trading Competition': '合約交易比賽',
  'Current total trading volume (USDT)': '當前總交易量（USDT）',
  'Ends in': '距離結束',
  'Withdraw collateral': '提取保證金',
  'Deposit collateral': '存入保證金',
  'Open Orders': '當前委托線',
  'Order History': '歷史委托',
  Bar: '美國線',
  Candle: 'K線圖',
  Line: '線形圖',
  Area: '面積圖',
  'Hollow Candle': '空心K線圖',
  Baseline: '基準線',
  'Hi-Lo': '高低',
  O: '開',
  H: '高',
  L: '低',
  C: '收',
  'Event Rules >': '活動規則 >',
  'Competitions will reward traders based on the volume target and rank.':
    '比賽將根據交易量目標和排名對交易者進行獎勵。',
  'Trade Now': '立即交易',
  'Current Total Volume': '當前交易總額',
  'TP/SL Keep Leverage': 'TP/SL 保持槓桿倍數',
  Leaderboard: '排行榜',
  ROI: 'ROI',
  Million: '百萬',
  PositionStatusExecuted: '已執行：指這筆訂單已執行成功。',
  PositionStatusRequest: '請求：指這筆訂單請求成功，但需耐心等待訂單執行。',
  PositionStatusCancelled: '已取消：指這筆訂單已取消。',
  '15-minute waiting period for selling purchased BLP.': '購買的BLP出售有15分鐘等待時間。',
  'System is currently upgrading...': '系統正在升級中...',
  'Copy Link': '複製連結',
  'Referral Code copied to your clipboard': '推薦碼已複製到您的剪貼簿',
  'Total Discount': '總折扣',
  'Total Rebate': '總返利',
  'Total Discounts Earned by this Account as a Trader.': '該帳戶作為交易者賺取的總折扣。',
  'Total Trading Volume with Valid Referral Codes for this Account.': '此帳戶綁定有效推薦碼的交易總數。',
  'Total Rebates Earned by this Account as a Referrer.': '該帳戶作為推薦者賺取的總返利。',
  'Total Trading Volume of Recommended Traders for this Account.': '該帳戶推薦交易者的交易總量。',
  'Total Number of Traders Recommended by this Account.': '該帳戶推薦的交易者總數。',
  Doc: '文檔',
  Audit: '審計',
  'Transaction Records': '交易記錄',
  'Reward Records': '獎勵記錄',
  'Total Amount': '總金額',
  'Total Buy Amount': '總購入金額 = 價格 * 數量 + 費用',
  'Total Sell Amount': '總賣出金額 = 價格 * 數量 - 費用',
  'Transaction was cancelled.': '您已拒絕授予權限，請檢查應用程式。',
  'Market Order Beyond the slippage price and canceled.': '市價單價格超過滑點價格。',
  handCancel: '您已拒絕授予權限，請檢查應用程式。',
  'System Cancel': '系統取消。',
  'Error encountered during contract execution(1000).': '系统错误。',
  Skip: '跳過',
  'Phase II': '二期',
  'Exclusive benefits and rewards for you to unlock, and special rewards for new users!':
    '獨家福利等你解鎖，更有新用戶專屬獎勵！',
  'Will Start In': '開始於',
  'So Stay Tuned!': '敬請期待！',
  'Invite Contest': '邀請交易賽',
  'Invite to earn $2,300': '邀請贏$2,300獎勵',
  'Trading Volume Contest': '交易量評比賽',
  'Trade Futures Volume earn $46,500': '交易贏$46,500獎勵',
  'Trading ROI Contest': '勝率排名賽',
  'Trade Futures ROI to earn $1,200': '收益評比贏$1,200獎勵',
  'Trading Contest': '交易大賽',
  'During the event period, the top 50 future traders will share $46,500 USDT prize pool, based on total trading volume reached on the blex exchange, and their future trading volume.':
    '活動期間，官方將依據blex平台總交易量及用戶交易量排名來瓜分共 $46,500 USDT獎池，具體規則如下。',
  'Detail Rules': '詳細規則',
  'When Total Trading Volume Reaches': '當交易總額達到',
  'During the event period, invite your friends to complete a future trading in any value via your invitation code/link. Both the inviting user and the invited user will get $2 USDT. The reward is limited to the first 500 valid invitations, first come first served.':
    '邀請人透過邀請碼/邀請連結邀請好友在BLEX去中心化交易所完成1筆任意金額的合約交易，即視為有效邀請。每完成一次有效邀請，邀請人和被邀請人均可獲得{value}獎勵，獎勵僅限前500位有效邀請，先到先得。',
  'Note: The maximum reward for an individual user is $20 USDT.': '注意：單人最高可得 {value} 的邀請獎勵。',
  'Invite Now': '立即邀請',
  'ROI Rank': 'ROI（收益率）排名',
  'During the event, $1,200 USDT will reward users according to their future trading profitability (ROI) rank. Both the top3 and top 498-500 will get $200 USDT each in this session.':
    '活動期間，用戶在BLEX平台開倉交易，依照交易獲利率（ROI）排名，活動結束結算，排名 {value1} 和{value2} 的用戶，均可獲得 {value3} 獎勵。',
  Notices: '注意',
  '1.Users must meet the requirements during the event to be eligible for the rewards.':
    '1.用戶必須滿足活動要求方有資格獲得獎勵。',
  '2.The rewards will be issued within 7 working days after the event.':
    '2.所有獎勵將在活動結束後 {value} 個工作天內發放。',
  '3.The trading volume only includes Futures trading.Trading Volume = Opened Positions + Closed Positions.':
    '3.交易量計算僅限於合約交易。 {value}',
  '4.Users need to accumulate at least 5U to win.': '4.用戶需累計至少 {value} 方可發獎。',
  '5.No spam is allowed, or you will be disqualified.': '5.禁止任何違規刷量行為，一經發現，將取消獲獎資格。',
  '6.BLEX reserves the right to the final interpretation of this event.': '6.本次活動解說權歸blex官方所有。',
  '7.If you have any questions, please contact our admin via': '7.如有任何問題，請加入社群開票諮詢管理員尋求協助',
  'Your ROI': '你的ROI(收益率）',
  'ROI = (Profit + UnProfit) / Cum.Collateral': '收益率ROI =（實現盈虧+未實現盈虧）/ 累計保證金',
  'Cum.Collateral = Total Opening position + Total Deposit Collateral': '累計保證金 = 累計開倉量+累計儲值保證金',
  'Volume = Total Size (Open Size + Close Size)': '交易量 = 部位總和（開倉量 + 平倉量）',
  'Trading Volume = Opened Positions + Closed Positions.': '交易量 = 開倉量 + 平倉量。',
  'discord or telegram': 'DC',
  'In addition, the top 10 inviting users can get an additional $30 USDT reward after the event ends.':
    '活動結束，有效邀請 {value} 的用戶，可再額外獲得 {value1} 獎勵。',
  '3.The trading volume only includes Futures trading.': '3.交易量計算僅限於合約交易。',
  Top3: '前3名',
  'Bottom 3': '後3名',
  'no list data': '暫無榜單訊息',
  'mail notice': '郵件通知',
  'mail info Add': '錢包中心新增了「郵件通知」功能，啟用後可以及時接收有關倉位的相關信息。',
  'open mail subscrip': '在這裡同樣可以啟用「郵件訂閱」功能。',
  'got it': '我知道了',
  'provide mail account': '請提供您常用的電子郵件地址，我們將隨時向您發送與倉位相關的預警提示。',
  'please enter email account': '請輸入您的電子郵件地址。',
  'error style re enter': '郵箱格式不正確，請重新輸入。',
  'confirm open': '確認開啟',
  'close mail notice': '關閉郵件通知',
  'close mail info': '關閉郵件通知後，您將無法及時收到有關倉位的預警提示。',
  'comfir close': '確認關閉',
  considerate: '考慮一下',
  'open info': '開啟郵件通知，即時獲取您的倉位信息',
  'please enter mail': '請輸入您的電子郵件地址',
  'unvaild mail': '郵箱格式不正確，請重新輸入',
  'open mail success': '郵件通知已成功啟用，請隨時查看您的電子郵件',
  'close mail success': '您已成功取消郵件通知，平台將不再向您發送與倉位相關的預警提示',
  next: '下一步',
  'Internal JSON-RPC error.': '內部 JSON-RPC 錯誤。',
  'Market Order Beyond the slippage price and canceled': '市價訂單超過滑點價格，已取消。',
  'The index price is referenced using Chainlink + FastPrice (mainstream CEX prices), and there may be some differences from the actual transaction price.':
    '指數價格僅供參考，以實際成交價格為準。',
  'PNL after fees': '實際損益 = 損益 - 倉位費 - 資金費 - 執行費 - 清算費',
  'Historical Peak': '歷史峰值',
  'Super Referrers': '超級返傭',
  'Contact us for more discounts': '聯系我們獲取更多折扣',
  'PNL / ROI': 'PNL / ROI',
  'Target Price': '目標價格',
  'Calculations are for reference only.': '計算僅供參考。',
  Calculator: '計算器',
  Calculate: '計算',
  'The initial collateral calculation here does not include any fees or opening losses that need to be deducted.':
    '此處的初始保證金計算未包括任何需要扣除的手續費用或開倉損失。',
  'PNL After Fees': '扣除費後的損益',
  'Unable to reach this return rate.': '無法達到該報酬率。',
}
